$(window).on('load',function(){
    $('input[type="file"]').change(function(){

        if (this.files && this.files[0]) {
            var img = $('#myImg');
            img.onload = () => {
                URL.revokeObjectURL(img.src);  // no longer needed, free memory
            }
            img.attr('src', URL.createObjectURL(this.files[0])); // set src to blob ur
    }
    })
})

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}



$(function(){

    $('.btnClose').on('click',function(){
        ctx.clearRect(0,0,canvas.width, canvas.height);
        ctx.drawImage(img,0,0);
        $("input").val("");

    })
    $('.btnCreate').on('click',function(){
        var canvas = document.getElementById('demo');
        var ctx = canvas.getContext('2d');

        switch(this.id){
            case "test":
                canvas.setAttribute('width','650px');
                canvas.setAttribute('height','452px');
                ctx.clearRect(0,0,canvas.width, canvas.height);
                var img = document.getElementById(this.id + "_img");
                ctx.drawImage(img,0,0)
                $('#gender').show();
                $('#mname').show();
                $('#btnSubmit1').show();
                $('#btnSubmit2, #btnSubmit3, #btnSubmit4').hide();
                ctx.font = "bold 13pt Calibri"

                $('#btnSubmit1').on('click',function(e){

                    ctx.fillText($('#lname').val() + " "+ $('#fname').val() + " "+ $('#mname').val(), 240,150)
                    ctx.fillText("Flipino", 240,195);
                    if($('#inlineRadio1').is(":checked")){
                        ctx.fillText("M", 335,195)
                    } else if($('#inlineRadio2').is(":checked")){
                        ctx.fillText("F", 335,195)
                    } else {
                        ctx.fillText("N/A", 335,195)
                    }
                    ctx.fillText($('#dob').val(), 380,195);
                    var weight = getRandomInt(45,90);
                    var height = Math.round(getRandomArbitrary(1.5,2)*100)/100
                    var address = Math.round(getRandomInt(15,200)) + " Peter Avenue Lfs"
                    ctx.fillText(weight, 510,195)
                    ctx.fillText(height, 580,195)
                    try{
                        ctx.drawImage(document.getElementById('myImg'), 43,120,180,235)
                    } catch(e){
                        console.log(e)
                    }
                    ctx.fillText(address, 240,240)
                    ctx.fillText("D82-91-963286", 240,295)
                    ctx.fillText("2024/10/22", 390,295)
                    ctx.fillText("D82", 520,295)
                    ctx.fillText("A+", 590,295)
                    ctx.fillText("1.2", 410,335)
                    ctx.fillText("NONE", 500,335)
                });
                break
            case "test2":
                canvas.setAttribute('width','462px');
                canvas.setAttribute('height','301px');
                ctx.clearRect(0,0,canvas.width, canvas.height);
                var img = document.getElementById(this.id + "_img");
                ctx.drawImage(img,0,0)
                $('#mname').hide();
                $('#gender').hide();
                $('#btnSubmit2').show();
                $('#btnSubmit1, #btnSubmit3, #btnSubmit4').hide();

                $('#btnSubmit2').on('click',function(e){
                    ctx.font = "bold 11pt Calibri"
                    ctx.fillText(Math.round(getRandomInt(15573826495847,84738450683726)), 180,83);
                    ctx.fillText("2034/"+ Math.round(getRandomInt(10,12)) + "/"+ Math.round(getRandomInt(10,26)) , 185,103)

                    ctx.fillText($('#lname').val(), 185,125)
                    ctx.fillText($('#fname').val(), 185,140)
                    ctx.fillText($('#dob').val(), 185,185)
                      try{
                        ctx.drawImage(document.getElementById('myImg'), 20,51,134,179)
                    } catch(e){
                        console.log(e)
                    }
                });
                break
            case "test3":
                canvas.setAttribute('width','550px');
                canvas.setAttribute('height','349px');
                ctx.clearRect(0,0,canvas.width, canvas.height);
                var img = document.getElementById(this.id + "_img");
                ctx.drawImage(img,0,0)
                $('#gender').show();
                $('#mname').hide();
                $('#btnSubmit3').show();
                $('#btnSubmit2, #btnSubmit1, #btnSubmit4').hide();
                $('#btnSubmit3').on('click',function(e){
                    ctx.font = "bold 13pt Calibri"
// 
                    ctx.fillText($('#lname').val(), 230,102)
                    ctx.fillText($('#fname').val(), 230,150)
                    if($('#inlineRadio1').is(":checked")){
                        ctx.fillText("M", 220,185)
                    } else if($('#inlineRadio2').is(":checked")){
                        ctx.fillText("F", 220,185)
                    } else {
                        ctx.fillText("N/A", 220,185)
                    }
                    ctx.fillText($('#dob').val(), 410,185)
                      try{
                        ctx.drawImage(document.getElementById('myImg'), 36,75,153,187)
                    } catch(e){
                        console.log(e)
                    }
                });

                break
            case "test4":
                canvas.setAttribute('width','550px');
                canvas.setAttribute('height','350px');
                ctx.clearRect(0,0,canvas.width, canvas.height);
                var img = document.getElementById(this.id + "_img");
                ctx.drawImage(img,0,0)
                $('#gender').hide();
                $('#mname').hide();

                $('#btnSubmit4').show();
                $('#btnSubmit2, #btnSubmit3, #btnSubmit1').hide();
                $('#btnSubmit4').on('click',function(e){
                    ctx.font = "bold 13pt Calibri"
                    ctx.fillText(Math.round(getRandomInt(15573826495847,84738450683726)), 223,131);
// 
                    ctx.fillText($('#lname').val(), 230,182)
                    ctx.fillText($('#fname').val(), 230,200)
                    ctx.fillText($('#dob').val(), 235,251)
                      try{
                        ctx.drawImage(document.getElementById('myImg'), 36,111,153,187)
                    } catch(e){
                        console.log(e)
                    }
                });
                break
            case "test5":
                canvas.setAttribute('width','550px');
                canvas.setAttribute('height','350px');
                ctx.clearRect(0,0,canvas.width, canvas.height);
                var img = document.getElementById(this.id + "_img");
                ctx.drawImage(img,0,0)
                $('#mname').hide();
                $('.sub').hide();
                $('#btnSubmitXMDT30').show();
                $('#btnSubmitXMDT30').on('click',function(e){
                    ctx.font = "15pt Calibri"
                    if($('#inlineRadio1').is(":checked")){
                        ctx.fillText("M", 470,285)
                    } else if($('#inlineRadio2').is(":checked")){
                        ctx.fillText("F", 470,285)
                    } else {
                        ctx.fillText("N/A", 470,285)
                    }
                    ctx.fillText($('#fname').val(), 230,182)
                    ctx.fillText($('#lname').val(), 250,125)
                    ctx.fillText($('#dob').val(), 281,281)
                        try{
                        ctx.drawImage(document.getElementById('myImg'), 20,75,153,210)
                    } catch(e){
                        console.log(e)
                    }
                });
                break
            case "test6":
                canvas.setAttribute('width','550px');
                canvas.setAttribute('height','350px');
                ctx.clearRect(0,0,canvas.width, canvas.height);
                var img = document.getElementById(this.id + "_img");
                ctx.drawImage(img,0,0)
                $('#mname').hide();
                $('.sub').hide();
                $('#btnSubmitXMDT31').show();
                $('#btnSubmitXMDT31').on('click',function(e){
                    ctx.font = " bold 12pt Calibri"
                    ctx.fillText(Math.round(getRandomInt(15573826495847,84738450683726)), 350,275);
                    ctx.fillText("2034/"+ Math.round(getRandomInt(10,12)) + "/"+ Math.round(getRandomInt(10,26)) , 250,275)
// 

                    if($('#inlineRadio1').is(":checked")){
                        ctx.fillText("M", 245,195)
                    } else if($('#inlineRadio2').is(":checked")){
                        ctx.fillText("F", 245,195)
                    } else {
                        ctx.fillText("N/A", 245,195)
                    }
                    ctx.fillText($('#fname').val(), 260,98)
                    ctx.fillText($('#lname').val(), 260,130)
                    ctx.fillText($('#dob').val(), 285,163)
                    ctx.fillText(" North Korea", 280,225)
                    try{
                        ctx.drawImage(document.getElementById('myImg'), 30,95,142,180)
                    } catch(e){
                        console.log(e)
                    }
                });
                break
            case "test7":
                canvas.setAttribute('width','550px');
                canvas.setAttribute('height','350px');
                ctx.clearRect(0,0,canvas.width, canvas.height);
                var img = document.getElementById(this.id + "_img");
                ctx.drawImage(img,0,0)
                $('#mname').hide();
                $('.sub').hide();
                $('#btnSubmitXMDT24').show();
                $('#btnSubmitXMDT24').on('click',function(e){
                    ctx.font = " bold 12pt Calibri"
                    ctx.fillText("2034/"+ Math.round(getRandomInt(10,12)) + "/"+ Math.round(getRandomInt(10,26)) , 455,345)
                    ctx.fillText("2021/"+ Math.round(getRandomInt(10,12)) + "/"+ Math.round(getRandomInt(10,26)) , 325,345)
    // 

                    if($('#inlineRadio1').is(":checked")){
                        ctx.fillText("M", 355,242)
                    } else if($('#inlineRadio2').is(":checked")){
                        ctx.fillText("F", 355,242)
                    } else {
                        ctx.fillText("N/A", 355,242)
                    }
                    ctx.fillText($('#fname').val(), 320,152)
                    ctx.fillText($('#lname').val(), 405,196)
                    ctx.fillText($('#dob').val(), 435,265)
                    ctx.fillText("California", 455,285)
                    try{
                        ctx.drawImage(document.getElementById('myImg'), 30,98,192,250)
                    } catch(e){
                        console.log(e)
                    }
                });
                break
            case "test8":
                canvas.setAttribute('width','550px');
                canvas.setAttribute('height','350px');
                ctx.clearRect(0,0,canvas.width, canvas.height);
                var img = document.getElementById(this.id + "_img");
                ctx.drawImage(img,0,0)
                $('#mname').hide();
                $('.sub').hide();
                $('#btnSubmitXMDT25').show();
                $('#btnSubmitXMDT25').on('click',function(e){
                    ctx.font = " bold 12pt Calibri"
                    ctx.fillText(Math.round(getRandomInt(1557382649584, 8473845068726)),465, 63);
                    ctx.fillText("2034/"+ Math.round(getRandomInt(10,12)) + "/"+ Math.round(getRandomInt(10, 26)) ,330, 320)
                    if($('#inlineRadio1').is(":checked")){
                        ctx.fillText("M", 500,160)
                    } else if($('#inlineRadio2').is(":checked")){
                        ctx.fillText("F", 500,160)
                    } else {
                        ctx.fillText("N/A", 500,160)
                    }
                    ctx.fillText($('#fname').val(), 160, 95)
                    ctx.fillText($('#lname').val(), 160, 120)
                    ctx.fillText($('#dob').val(), 330, 160)
                    ctx.fillText("Prague", 330, 210)
                    try{
                        ctx.drawImage(document.getElementById('myImg'), 35, 130, 190, 220)
                    } catch(error){
                        console.log(error)
                    }
                });
                break
        }

        var values = {};
        $('#btnReset').on('click',function(){
            ctx.clearRect(0,0,canvas.width, canvas.height);
            ctx.drawImage(img,0,0);
            $("input").val("");
            $('#myImg').attr('src','data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOQAAADdCAMAAACc/C7aAAAAaVBMVEXDw8MAAADGxsaXl5fJycnMzMxSUlKRkZF1dXV5eXnCwsIFBQWlpaV+fn66urqurq5dXV1sbGxMTEyKiopXV1czMzOcnJwaGhqoqKiEhIQlJSUrKysODg5mZmZHR0ezs7M7OzsVFRU5OTmFwHepAAAC+klEQVR4nO3bi1KjMBSAYXIarIbea2uttVXf/yE36Q0qobrITHP0/2Z2Zt2xDP+GQEDMMgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAANQ56dCtY+JcZge9zgzsrXvipGc61EtzLO29KbpKLMx9mkPpI83H410XFiblyGFufy7Ph0lHPnSya/aByFuqRv7sSqchUvwV83k4EHFtt6QhcrwOl4GXZdv9VBApq3CdK/w1c9nykNUQ+XK6pG/abin5SFmWy5Z+u6FMP7K6unttt6cKIqflSE4a9tQd/zRtKflI6ZeRw6Y9deFS2ryl5COzUXkzMWoIcZm45bixUkGkfd6PormyyBMZPxkzaVouKIjM7PAwjtPm/XRP4Rse8/hgaoh0djzdbl9XeePJxc7CUBdmHi/REOkPx3Bb2DTlnOwP6L34vNQReZ3szqffRXRa/oJIcetzpJnksS2pj3T5pPrIqmfr6wL9kbZvynWf/8uuPi21Rzrxa4WijCzMW/0j6iKdV/lSZPH5Ges0V3+4flqi+uV77Ql07QmCtkg7GlcumM4uI0/ZC+UjGe67FpVl+qhWGGw/f0pRpIS99aeWjTtV2rdopBleTktNkZkMwvmzMHf20BCaY42FWV3MXFWR2eZY8ezvpY/N8aF8UhuZz84jtV+Iu/d4YfiGu+oHFUX6e43i1LDODs1FfCT9P8+lXN7piZRxNWOS23nTOB7syvsRPZHZ+qKhv2uckMfBLqelmsjLew1/anlpOlbLwT5vSUeks/2rQVG9U5eSyLC0+f+3JE53XToiRT6+OjhjPjRFunz6dVHM9DAtVURG7zW+ZbAfSw2R0mpCHvi1vFMRabctC/1/zdaKisjTTwnaRIYfhCmIdHbQunF/Rl5J8pEizfca37Pxkzr5yNnXHdfNJPGRHNrRvP9D81HqkbnNO5D2W5K//X1XFyK7kuyby3/iHXTp8rcJVmk2/onfCwEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAALi1f4DsKck70eEzAAAAAElFTkSuQmCC')
        })

        $('#btnDownload').on('click',function download(){
            var image = canvas.toDataURL("image/jpg");
            var link = document.createElement('a');
            link.download = "my-image.png";
            link.href = image;
            link.click();
        });
    })
});